import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  LockReset as LockResetIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material"
import { LoadingService, PopupService } from "react-ui-components/dist/components";
// Import required dependencies
import React, { useEffect, useState } from "react"
import { deleteDoc, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore"
import { getAuth, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth"

import { Form } from "@rjsf/mui"
import { JSONSchema7 } from "json-schema"
import { useParams } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"

interface User {
  id: string
  email: string
  roles: string[]
  disabled: boolean
  verified: boolean
  fcmWebToken?: string
  pushNotifications: boolean
  pushNotificationToken?: string
}

const ROLES = [
  {
    title: "User",
    type: "boolean",
    default: false,
  },
  {
    title: "Vendor",
    type: "boolean",
    default: false,
  },
  {
    title: "Admin",
    type: "boolean",
    default: false,
  },
  {
    title: "Super",
    type: "boolean",
    default: false,
  },
]

const UserAdminPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [user, setUser] = useState<User | null>(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const firestore = getFirestore()
  const auth = getAuth()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        LoadingService.show()
        if (id) {
          const userDoc = await getDoc(doc(firestore, "users", id))
          if (userDoc.exists()) {
            const userData = userDoc.data()
            setUser({
              id: userDoc.id,
              roles: userData.roles || [], // Handle missing roles array
              ...userData,
            } as User)
          }
        }
      } catch (error) {
        PopupService.pushPopup(`Error fetching user: ${error.message}`)
      } finally {
        LoadingService.hide()
      }
    }

    fetchUser()
  }, [id, firestore])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleToggleDisabled = async (disabled: boolean) => {
    try {
      LoadingService.show()
      if (user) {
        await updateDoc(doc(firestore, "users", user.id), { disabled })
        setUser({ ...user, disabled })
      }
    } catch (error) {
      PopupService.pushPopup(`Error toggling user disabled state: ${error.message}`)
    } finally {
      LoadingService.hide()
    }
  }

  const handleSendVerificationEmail = async () => {
    try {
      LoadingService.show()
      if (user?.email) {
        const currentUser = auth.currentUser
        if (currentUser?.email === user.email) {
          await sendEmailVerification(currentUser)
        }
      }
    } catch (error) {
      PopupService.pushPopup(`Error sending verification email: ${error.message}`)
    } finally {
      LoadingService.hide()
    }
  }

  const handlePasswordReset = async () => {
    try {
      LoadingService.show()
      if (user?.email) {
        await sendPasswordResetEmail(auth, user.email)
      }
    } catch (error) {
      PopupService.pushPopup(`Error sending password reset email: ${error.message}`)
    } finally {
      LoadingService.hide()
    }
  }

  const handleSendNotification = async () => {
    if (user?.pushNotifications && user.pushNotificationToken) {
      // Replace with your Firebase Cloud Messaging logic
      console.log(`Sending notification to token: ${user.pushNotificationToken}`)
    }
  }

  const handleRoleChange = async (role: string, checked: boolean) => {
    try {
      LoadingService.show()
      if (user) {
        const updatedRoles = checked ? [...user.roles, role] : user.roles.filter((r) => r !== role)
        await updateDoc(doc(firestore, "users", user.id), { roles: updatedRoles })
        setUser({ ...user, roles: updatedRoles })
      }
    } catch (error) {
      PopupService.pushPopup(`Error updating roles: ${error.message}`)
    } finally {
      LoadingService.hide()
    }
  }

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="User Admin Tabs">
        <Tab label="User Management" />
        <Tab label="Roles" />
        <Tab label="Notifications" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <Typography variant="h6">Manage User</Typography>
        {user && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #ddd",
              padding: 1,
            }}
          >
            <Typography>{user.email}</Typography>
            <Box>
              <Tooltip title="Edit Roles">
                <IconButton onClick={() => console.log("Edit roles", user.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Verification Email">
                <IconButton onClick={handleSendVerificationEmail}>
                  <EmailIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Password Reset">
                <IconButton onClick={handlePasswordReset}>
                  <LockResetIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Notification">
                <IconButton onClick={handleSendNotification}>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Toggle Disabled">
                <Switch
                  checked={!user.disabled}
                  onChange={() => handleToggleDisabled(!user.disabled)}
                />
              </Tooltip>
              <Tooltip title="Delete User">
                <IconButton onClick={() => deleteDoc(doc(firestore, "users", user.id))}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <Typography variant="h6">Roles Management</Typography>
        {user && (
          <Box>
            {ROLES.map((role) => (
              <FormControlLabel
                key={role.title}
                control={
                  <Checkbox
                    checked={user.roles.includes(role.title)}
                    onChange={(e) => handleRoleChange(role.title, e.target.checked)}
                  />
                }
                label={role.title}
              />
            ))}
          </Box>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <Typography variant="h6">Notifications</Typography>
        <Form
          schema={{} as JSONSchema7}
          onSubmit={(formData) => console.log("Notifications Sent", formData)}
          validator={validator}
        />
      </TabPanel>
    </Box>
  )
}

const TabPanel: React.FC<{ value: number; index: number; children: React.ReactNode }> = ({
  value,
  index,
  children,
}) => {
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
}

export default UserAdminPage
