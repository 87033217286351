import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

import CountryData from "country-data"
import { storage } from "../firebase"

export function getCountryInfo(code: string | number) {
  // console.log("CountryData.countries----CountryData.countries[code]-->", CountryData.countries[code])
  /*
  { 
    "alpha2": "DO",
    "alpha3": "DOM",
    "countryCallingCodes": ["+1 809", "+1 829", "+1 849"],
    "currencies": ["DOP"],
    "emoji": "🇩🇴",
    "ioc": "DOM", 
    "languages": ["spa"],
    "name": "Dominican Republic",
    "status": "assigned"
  }
  */
  return CountryData.countries[code]
}

export const sanitizeData = (data: any) => {
  const sanitizedData = { ...data }
  Object.keys(sanitizedData).forEach((key) => {
    if (sanitizedData[key] === undefined) {
      sanitizedData[key] = null
    }
  })
  return sanitizedData
}

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime() // Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0 // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const convertProperties = (props: any[]) => {
  return props
    .filter((x: { hideEdit: any }) => !x.hideEdit)
    .reduce(
      (
        prev: any,
        cur: { type: string; label: any; properties: any; items: any; enum: any; name: any },
      ) => {
        const obj =
          cur.type === "object"
            ? {
                type: "object",
                title: cur.label,
                properties: cur.properties,
              }
            : cur.type === "array"
            ? {
                type: "array",
                title: cur.label,
                items: cur.items,
              }
            : cur.enum
            ? { enum: cur.enum, type: cur.type || "string", title: cur.label }
            : { type: cur.type || "string", title: cur.label }
        return {
          ...prev,
          [cur.name]: obj,
        }
      },
      {},
    )
}

export const getSchema = (title: any, props: any[]) => {
  return {
    title,
    type: "object",
    required: props
      .filter((item: { required: any }) => {
        if (typeof item.required === "undefined") {
          return true
        }

        if (item.required) {
          return true
        }

        return false
      })
      .map((item: { name: any }) => item.name),
    properties: convertProperties(props),
  }
}

export const getHash = async (base64String: string) => {
  // Decode the base64 string into an ArrayBuffer
  const buffer = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))

  // Use the SubtleCrypto API to generate the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer)

  // Convert the hash buffer to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("")

  return hashHex
}

export const isBase64Image = (url: string) => {
  if (!url) {
    console.error("isBase64Image----url ERROR--->", url)
    return url
  }
  // Check if the URL is a base64 encoded string
  const base64Pattern = /^data:image\/(\w+);base64,/
  const match = url.match(base64Pattern)

  return match
}

export const getFileExtension = (url: string) => {
  // Check if the URL is a base64 encoded string
  const match = isBase64Image(url)

  if (match) {
    // If it's a base64 string, return the image type
    return match[1]
  } else {
    // Otherwise, extract the file extension from the URL
    const urlParts = url.split("?")[0] // Remove query parameters if any
    const fileName = urlParts.split("/").pop() // Get the file name
    const extension = fileName ? fileName.split(".").pop() : "" // Get the file extension

    return extension
  }
}

export const formatNumber = (number: string, locale = "en-US") => {
  if (!number) {
    return ""
  }
  const options = { maximumSignificantDigits: 21, maximumFractionDigits: 21 }

  if (typeof number === "string") {
    number = number || "0"

    let parsed = Number.parseFloat(number)
    parsed = isNaN(parsed) ? 0 : parsed
    const result = parsed.toLocaleString(locale, options)
    return result
  }

  return (number as string).toLocaleString() //locale, options)
}

export const formatCash = (n: number, maxAbbreviateDigits = 3, precision = 1) => {
  n = +(n || 0)
  maxAbbreviateDigits = Number(`1e${maxAbbreviateDigits}`)
  if (n < maxAbbreviateDigits) return n.toLocaleString()
  const abbreviations = ["K", "M", "B", "T"]
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(precision).toLocaleString() + abbreviations[0]
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(precision).toLocaleString() + abbreviations[1]
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(precision).toLocaleString() + abbreviations[2]
  if (n >= 1e12) return +(n / 1e12).toFixed(precision).toLocaleString() + abbreviations[3]
}

export const convertToMeasure = (val: string) => {
  if (!val) {
    return "0"
  }

  if (/\d+\D+$/.test(val)) {
    return val
  } else {
    return `${val}px`
  }
}

export function getLocationStr(
  loc: { street: string; city: string; province: string; county: string; country: string },
  t?: (arg0: string) => any,
) {
  if (!loc) {
    return ""
  }

  const street = loc.street && loc.street !== "*" ? `${loc.street} ` : ""
  const city = loc.city && loc.city !== "*" ? `${`${loc.city}, `}` : ""
  const province = loc.province && loc.province !== "*" ? loc.province : ""
  const county =
    loc.county && loc.county !== "*" ? (province ? `, ${loc.county} ` : ` ${loc.county} `) : ""
  let country = loc.country && loc.country !== "*" ? loc.country : ""

  country = province ? ` (${country})` : country
  // console.log("street || city || county || province -------->", { street, city, county, province })
  return street || city || county || province || country
    ? `${street}${city}${province}${county}${country}`
    : t
    ? t("any")
    : "*"
}

export function camelCase(str: string) {
  // Using replace method with regEx
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, "")
}

export function pascalCase(str: string) {
  return !str
    ? ""
    : str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
          return index === 0 ? word.toUpperCase() : word.toUpperCase()
        })
        .replace(/\s+/g, "")
}

export interface AnyObj {
  [key: string]: unknown
}

export function removeUndefinedFields(obj: AnyObj[] | AnyObj | null): AnyObj[] | AnyObj | null {
  if (obj === null || typeof obj !== "object") {
    return obj // Return non-objects as is (e.g., numbers, strings, etc.)
  }

  if (Array.isArray(obj)) {
    // Handle arrays by recursively cleaning each element
    const cleanedArray: any = obj
      .map((item) => removeUndefinedFields(item))
      .filter((item) => item !== undefined)
    return cleanedArray.length > 0 ? cleanedArray : null
  }

  const cleanedObj: AnyObj = {}
  Object.keys(obj).forEach((key) => {
    const value: any = obj[key]

    // Recursively clean nested objects
    const cleanedValue = removeUndefinedFields(value)

    if (cleanedValue !== undefined) {
      cleanedObj[key] = cleanedValue
    }
  })

  // If the cleaned object has no properties, return undefined to remove it
  return Object.keys(cleanedObj).length > 0 ? cleanedObj : undefined
}

/**
 * The `handleUpload` function in TypeScript React handles uploading images, either as base64 data or
 * from a URL, to a specified storage bucket and returns the updated image information.
 * @param props - - id: a string representing the ID of the item being uploaded
 * @returns The `handleUpload` function returns either the updated `imageInfo` object with the new
 * image URL added, or just the new image URL if `imageInfo` is not provided.
 */
export const handleUpload = async (props: {
  id: string
  fieldName: string
  image: string
  prevValue?: string
  imageInfo?: { [key: string]: string }
  imagesBucket: string
}) => {
  const { id, image, prevValue, imageInfo, fieldName, imagesBucket } = props

  if (prevValue && image === prevValue) {
    return image
  }

  const isBase64 = isBase64Image(image)

  if (!isBase64) {
    return imageInfo
      ? {
          ...imageInfo,
          image,
        }
      : image
  }

  const uid = generateUUID()
  const storageRef = ref(
    storage,
    `${imagesBucket}/${id}/${fieldName}/${uid}-${Date.now()}.${getFileExtension(image)}`,
  )

  const response = await fetch(image)
  const blob = await response.blob()
  await uploadBytes(storageRef, blob)

  const downloadURL = await getDownloadURL(storageRef)
  return imageInfo
    ? {
        ...imageInfo,
        image: downloadURL,
      }
    : downloadURL
}
