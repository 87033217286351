import { AnyObj, removeUndefinedFields } from "../utils"
import {
  DEFAULT_AVAILABLE_LOCATION,
  ILocation,
  LoadingService,
} from "react-ui-components/dist/components"
import React, { createContext, useContext, useEffect, useState } from "react"
import { auth, db } from "../firebase"
import { doc, getDoc, updateDoc } from "firebase/firestore"

import { useAuthState } from "react-firebase-hooks/auth"

export const AppConfigurationService = {
  getConfig: async (onSuccess: any) => {
    const docData = await getDoc(doc(db, "app-configurations", "display-content-config"))
    const data = docData.data()
    onSuccess && onSuccess(data)
  },

  fetchLocations: async (onSuccess: any) => {
    LoadingService.show(true)
    try {
      const docRef = doc(db, "app-configurations/availableLocations")
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const data = docSnap.data()
        onSuccess && onSuccess(data)
      }
    } catch (error) {
      console.error("Error fetching locations:", error)
    } finally {
      LoadingService.show(false)
    }
  },
}

const DEFAULT_DATA: {
  availableLocations: ILocation
  configuration: {
    raffleTypes: string[]
    rafflesScreen: {
      descriptionLength: number
      hideAllActions: boolean
      imageResizeMode: string
      rafflesImageHeight: number
      rafflesSectionImageHeight: number
    }
  }
  saveConfig: (data: any) => Promise<void>
  saveRaffle: (data: any) => Promise<any>
  disableRaffle: (id: string, disabled?: boolean) => Promise<any>
} = {
  availableLocations: { US: { FL: ["Orlando"] } },
  configuration: {
    raffleTypes: ["Raffle", "Event"],
    rafflesScreen: {
      descriptionLength: 50,
      hideAllActions: true,
      imageResizeMode: "contain",
      rafflesImageHeight: 500,
      rafflesSectionImageHeight: 500,
    },
  },
  saveConfig: async (_data: any) => {
    console.error("saveConfig not implemented", _data)
  },
  saveRaffle: async (_data: any) => {
    console.error("saveRaffle not implemented", _data)
  },
  disableRaffle: async (id: string, disabled = true) => {
    console.error("disableRaffle not implemented", { id, disabled })
  },
}
const AppConfigurationContext = createContext(DEFAULT_DATA)

export const useAppConfiguration = () => {
  return useContext(AppConfigurationContext)
}

export const AppConfigurationProvider = (props: {
  children: any
  defaultAppConfiguration?: null | undefined
}) => {
  const { children, defaultAppConfiguration = DEFAULT_DATA } = props
  const [configuration, setConfiguration] = useState<any>(defaultAppConfiguration)
  const [availableLocations, setAvailableLocations] = useState<ILocation>()

  const [user] = useAuthState(auth)

  async function saveConfig(data: AnyObj[] | AnyObj | null) {
    data = removeUndefinedFields({ ...data })
    const snapshot = doc(db, "app-configurations", "display-content-config")
    const id = snapshot.id
    await updateDoc(snapshot, { id, ...data })
  }

  async function disableRaffle(raffleId: string, disabled = true) {
    if (!raffleId) {
      return "No data to save"
    }
    const id = raffleId

    await updateDoc(doc(db, "raffles", id), { id, disabled })

    return "Success"
  }

  async function saveRaffle(raffleData: any) {
    if (!raffleData) {
      return "No data to save"
    }

    const data: any = removeUndefinedFields(raffleData)

    const id = raffleData.id

    await updateDoc(doc(db, "raffles", id), { id, ...data })

    return data
  }

  useEffect(() => {
    if (user) {
      AppConfigurationService.getConfig(setConfiguration)
      AppConfigurationService.fetchLocations(setAvailableLocations)
    }
  }, [user])

  return (
    <AppConfigurationContext.Provider
      value={{
        configuration,
        saveConfig,
        availableLocations: availableLocations || DEFAULT_AVAILABLE_LOCATION,
        saveRaffle,
        disableRaffle,
      }}
    >
      {children}
    </AppConfigurationContext.Provider>
  )
}
